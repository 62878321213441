import MD from '../assets/images/md_id.png';
import MP from '../assets/images/mp_id.png';
import VA from '../assets/images/va_id.png';
import UI from '../assets/images/ui_id.png';
import JD from '../assets/images/jd_id.png';
import JDK from '../assets/images/jdk_id.png';
import PC from '../assets/images/pc_id.png';
import TG from '../assets/images/tg_id.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Team = () => {
    const coreTeamMembers = [
        { name: "Vincent AUDRIC", image: VA, description: "CTO", linkedin: "https://www.linkedin.com/in/vaudric/" },
        /* { name: "Ugur ILERI", image: UI, description: "GM Digital Services", linkedin: "https://www.linkedin.com/in/ugurileri/" }, */
        { name: "Janyce DESIDERIO", image: JD, description: "General Secretary & Web Dev", linkedin: "https://www.linkedin.com/in/janyce-desiderio/" },
        { name: "Jérôme DE KERPEL", image: JDK, description: "Human Capital Director", linkedin: "https://www.linkedin.com/in/j%C3%A9r%C3%B4me-de-kerpel-04ba2a6a/" },
        { name: "Pierre COSTE", image: PC, description: "Digital Transformation Expert", linkedin: "https://www.linkedin.com/in/pierre-coste-b5a8b9/" },
        { name: "Tanzer GENC", image: TG, description: "Digital Transformation Expert", linkedin: "https://www.linkedin.com/in/tanzer-genc-931aa816/" },
    ];

    const experts = [
        { title: 'Cyber Security Expert & PM' },
        { title: 'Digital Transformation Expert' },
        { title: 'IT Technical Architect' },
        { title: 'Operations & TOS Expert' },
        { title: 'AI & Blockchain Expert' },
        { title: 'S/W Development Expert' },
        { title: 'Maritime Legal Expert' },
        { title: 'Renewable Energies Expert' },
        { title: 'QHSSE & Sustainability Expert' },
        { title: 'Expert Geographer & Geographic Information Systems (GIS)' },
        { title: 'Civil Engineer, PM & Lean Construction Expert' },
        { title: 'Civil Engineer, PM & Maritime Construction Expert' },
        { title: 'Civil Engineer, PM & Deep Sea Terminal Expert' },
        { title: 'Asset Management and Port Equipment Expert' },
    ];

    return (
        <div className="bg-customBlue w-full mx-0 px-0 py-8 animate-fadeInUp">
            <div className="mx-10 px-4">
                <div className="flex flex-col xl:flex-row justify-between xl:gap-10">
                    {/* Left Column: Our Two Partners */}
                    <div className="xl:w-2/5 w-full mb-8 xl:mb-0 xl:pr-4">
                        <h2 className="text-2xl font-bold mb-4 text-center text-gray-50">Our Two Partners</h2>
                        <div className="flex flex-col items-center lg:items-start md:flex-row justify-center md:space-x-2 gap-2 lg:w-1/2 xl:w-full mx-auto">
                            <div className="bg-white rounded-lg overflow-hidden flex flex-row md:flex-col items-start md:items-center w-full md:w-1/3 lg:w-1/2 md:pt-2 md:mb-0 p-2">
                                <img src={MD} alt="Matthieu Desiderio" className="w-1/3 h-32 md:w-full md:h-40 xl:h-48 object-contain" />
                                <div className="p-2 text-left md:text-center">
                                    <h3 className="text-lg 2xl:text-xl font-bold">Matthieu DESIDERIO</h3>
                                    <p className="text-gray-600 text-xs">President & Founder</p>
                                    <a href="https://www.linkedin.com/in/matthieudesiderio/" target="_blank" rel="noopener noreferrer" className="text-customBlue hover:text-customRed mt-2 inline-block" title="Matthieu Desiderio's LinkedIn">    
                                        <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                    </a>
                                </div>
                            </div>
                            <div className="bg-white rounded-lg overflow-hidden flex flex-row md:flex-col items-start md:items-center w-full md:w-1/3 lg:w-1/2 md:pt-2 md:mb-0 p-2">
                                <img src={MP} alt="Mathew Paul" className="w-1/3 h-32 md:w-full md:h-40 xl:h-48 object-contain" />
                                <div className="p-2 text-left md:text-center">
                                    <h3 className="text-lg 2xl:text-xl font-bold">Mathew PAUL</h3>
                                    <p className="text-gray-600 text-xs">CEO</p>
                                    <a href="https://www.linkedin.com/in/mathew-paul-810409b4/" target="_blank" rel="noopener noreferrer" className="text-customBlue hover:text-customRed mt-2 inline-block" title="Mathew Paul's LinkedIn">        
                                        <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Right Column: Our Core Team */}
                    <div className="xl:w-3/5 w-full">
                        <h2 className="text-2xl font-bold mb-4 text-center text-gray-50">Our Core Team</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-y-4">
                            {coreTeamMembers.map((member, index) => (
                                <div key={index} className="bg-white rounded-lg overflow-hidden flex flex-row items-start w-full max-w-xs mx-auto h-36">
                                    <img src={member.image} alt={member.name} className="object-contain w-auto h-full my-auto p-2" />
                                    <div className="p-2 text-left my-auto">
                                        <h3 className="text-md 2xl:text-xl font-bold">{member.name}</h3>
                                        <p className="text-gray-600 text-xs">{member.description}</p>
                                        <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="text-customBlue hover:text-customRed mt-2 inline-block" title={`${member.name}'s LinkedIn`}>                                            
                                            <FontAwesomeIcon icon={faLinkedin} size="2x" />
                                        </a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                {/* Our Pool of Experts Section */}
                <div className="mt-10">
                    <h2 className="text-2xl font-bold mb-4 text-center text-gray-50">Our Network of Experts</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 lg:gap-6 text-center">
                        {experts.map((expert, index) => (
                            <div key={index} className="bg-white rounded-lg p-4 shadow-lg">
                                <h3 className="text-md font-bold text-customBlue">{expert.title}</h3>
                            </div>
                        ))}
                        <div className="bg-customRed rounded-lg p-4 shadow-lg">
                                <h3 className="text-md font-bold text-white">Join the Team: Send your CV to 1407</h3>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Team;