import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import LegalNotice from "./pages/legalNotice";
import Home from "./pages/home";
import Strategy from "./pages/strategy";
import Transactions from "./pages/transactions";
import Operations from "./pages/operations";
import Projects from "./pages/projects";
import Airport from "./pages/airport";
import ScrollToTop from "./components/scrollToTop";
import CookieConsentBanner from "./components/cookieConsentBanner";
import { HelmetProvider } from 'react-helmet-async';

// Function to delete GA cookies
function deleteGACookies() {
  const cookies = document.cookie.split(";");
  cookies.forEach(cookie => {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie.trim();

    // Attempt to delete the cookie with various attributes
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=mille407.com`;
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;secure;SameSite=None`;
  });
}

function App() {
  const [hasConsent, setHasConsent] = useState(null);

  useEffect(() => {
    const consent = localStorage.getItem("cookie-consent");
    const expiration = localStorage.getItem("cookie-consent-expiration");

    if (expiration && new Date() > new Date(expiration)) {
      localStorage.removeItem("cookie-consent");
      localStorage.removeItem("cookie-consent-expiration");
      setHasConsent(null);
      deleteGACookies();
      return;
    }

    // Determine consent state
    if (!consent) {
      setHasConsent(null);
      deleteGACookies();
      return;
    }

    if (consent === "accepted") {
      setHasConsent(true);
      window.gtag('config', 'G-R6G9TTLD4H');  // Initialize GA directly
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'granted',
        ad_personalization: 'denied',
        ad_user_data: "denied"
      });
    } else if (consent === "refused") {
      setHasConsent(false);
      window.gtag('consent', 'update', {
        ad_storage: 'denied',
        analytics_storage: 'denied',
        ad_personalization: 'denied',
        ad_user_data: "denied"
      });
      deleteGACookies(); // Ensure cookies are deleted when consent is refused
    } else {
      setHasConsent(null);
    }
  }, []);

  const handleAcceptCookies = () => {
    const expirationDate = new Date();
    expirationDate.setMonth(expirationDate.getMonth() + 12); // Set expiration for 12 months
    localStorage.setItem("cookie-consent", "accepted");
    localStorage.setItem("cookie-consent-expiration", expirationDate.toISOString());
    setHasConsent(true);
    window.gtag('config', 'G-R6G9TTLD4H');  // Initialize GA directly
    window.gtag('consent', 'update', {
      ad_storage: 'denied',
      analytics_storage: 'granted',
      ad_personalization: 'denied',
      ad_user_data: "denied"
    });
  };

  const handleRefuseCookies = () => {
    localStorage.setItem("cookie-consent", "refused");
    setHasConsent(false);
    window.gtag('consent', 'update', {
      ad_storage: 'denied',
      analytics_storage: 'denied',
      ad_personalization: 'denied',
      ad_user_data: "denied"
    });
    deleteGACookies(); // Ensure cookies are deleted when consent is refused
  };

  // Manage consent (reset consent state and show banner)
  const handleManageCookies = () => {
    localStorage.removeItem("cookie-consent");
    localStorage.removeItem("cookie-consent-expiration");
    setHasConsent(null); // Reset consent state
    deleteGACookies();
  };

  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop>
          <div className="App">
            <Header />
            {hasConsent === null && (
              <CookieConsentBanner
                onAccept={handleAcceptCookies}
                onRefuse={handleRefuseCookies}
              />
            )}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/strategy.html" element={<Strategy />} />
              <Route path="/transactions.html" element={<Transactions />} />
              <Route path="/operations.html" element={<Operations />} />
              <Route path="/projects.html" element={<Projects />} />
              <Route path="/airport.html" element={<Airport />} />
              <Route path="/legal-notice.html" element={<LegalNotice handleManageCookies={handleManageCookies} />} />
            </Routes>
            <Footer />
          </div>
        </ScrollToTop>
      </Router>
    </HelmetProvider>
  );
}

export default App;