import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import headerLogo from '../assets/images/logo_1407_only_white.png';
const MetaTags = ({ title, description, keywords, url }) => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="keywords" content={keywords} />

                {/* Favicon */}
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/mille407.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#ea1313" />
                <meta name="msapplication-TileColor" content="#2b5797" />
                <meta name="theme-color" content="#ffffff" />

                {/* Preload Header Image */}
                <link rel="preload" href={headerLogo} as="image" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content="https://mille407.com/images/metacard-1407.webp" />
                <meta property="og:site_name" content="Mille407" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:url" content={url} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content="https://mille407.com/images/metacard-1407.webp" />

                {/* Additional meta tags */}
                <link rel="canonical" href={url} />
            </Helmet>
        </HelmetProvider>
    );
};

export default MetaTags;