const CookieConsentBanner = ({ onAccept, onRefuse }) => {
  return (
    <div id="cookieConsent" className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10" aria-labelledby="cookieConsentTitle"
    aria-describedby="cookieConsentDescription">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full text-center">
        <h3 className="text-xl font-semibold mb-4">Cookie Consent</h3>
        <p className="mb-4">
          We use essential cookies for site functionality and analytics cookies to help us improve user experience.
          Learn more in our&nbsp;
          <a href="/legal-notice.html#cookies-policy" className="text-customBlue underline">Cookies Policy</a>.
        </p>
        <div className="flex justify-center space-x-4">
          <button
            id="acceptCookies"
            className="bg-customBlue text-white py-2 px-4 rounded hover:bg-customRed"
            onClick={onAccept}
          >
            Accept All
          </button>
          <button
            id="refuseCookies"
            className="bg-gray-300 text-gray-700 py-2 px-4 rounded hover:bg-gray-400"
            onClick={onRefuse}
          >
            Refuse Analytics
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieConsentBanner;